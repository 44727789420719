import './index.scss'
import * as yup from 'yup'
import React, { useState } from 'react'
import styled from 'styled-components'
import { Button } from '../../atoms/Button'
import { Event as GAEvent } from '../GA'
import { PublicSubscriptionTypes } from '../../api'
import { ResponseStatus } from '../../queries/types'
import { useForm } from 'react-hook-form'
import { useLeadSubscribe } from '../../queries/leadSubscribe'
import { yupResolver } from '@hookform/resolvers/yup'

const schema = yup.object().shape({
  email: yup.string().email().required()
})

export const FooterSubscribeForm = styled(({ className }) => {
  const [alreadySubscrib, setAlreadySubscrib] = useState(false)

  const { register, handleSubmit, reset } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema)
  })

  const [leadSubscribe] = useLeadSubscribe()

  const submitForm = async (data) => {
    try {
      const body = await leadSubscribe({
        variables: {
          input: {
            email: data.email,
            type: PublicSubscriptionTypes.FOOTER
          }
        }
      })

      if (body?.data?.leadSubscribe?.status === ResponseStatus.SUCCESS) {
        setAlreadySubscrib(!alreadySubscrib)
        reset()
        GAEvent.NewsletterSubcribe()
      }
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <div className={className}>
      {!alreadySubscrib ? (
        <div className="footer__wrap">
          <p className="h4 footer__subscrib-desc">
            Одно письмо в неделю со свежими новостями и акциями
          </p>
          <form
            noValidate
            className="footer__subscrib subscrib"
            onSubmit={handleSubmit(submitForm)}
          >
            <div className="subscrib__field formfield formfield_subscrib">
              <input
                ref={register}
                className="formfield__input"
                name="email"
                placeholder="Ваш e-mail"
                type="email"
              />
            </div>
            <Button.NewPrimary
              className="subscrib__submit"
              size="medium"
              type="submit"
            >
              <img
                alt="Отправить"
                className="subscrib__submit_short"
                src="/img/icons/send.svg"
              />
              <span className="subscrib__submit_full">Подписаться</span>
            </Button.NewPrimary>
          </form>
          <p className="footer__subscrib-desc2">
            Подписываясь, я соглашаюсь на обработку персональных данных в
            соответствии с ФЗ РФ № 152-ФЗ «О персональных данных», а также
            с&nbsp;Политикой конфиденциальности
          </p>
        </div>
      ) : (
        <p className="footer__already-subscribe">
          Вы подписались на
          <br /> еженедельную
          <br /> рассылку!
        </p>
      )}
    </div>
  )
})``
