import { REGISTRATION_WEBSITE_RESULT } from './organisms/RegistrationModals/RegistrationModal/vars'
import { gql } from '@apollo/client'

export const baseURL =
  process.env.BACKEND_OVERRIDE_URL ?? `${process.env.GATSBY_SITEURL}/backend`

export function handleErrors(response) {
  if (response.status !== 200) {
    return response.text().then((text) => {
      throw new Error(text)
    })
  } else {
    return response
  }
}

export const SEND_REGISTRATION_REQUEST = gql`
  mutation sendRegistrationRequest($input: SendRegistrationRequestInput!) {
    sendRegistrationRequest(input: $input) {
      ... on AuthErrorResponse {
        code
        message
      }
      ... on SendRegistrationRequestResponseSuccess {
        token
        datetime
        retryAvailableAfter
        bitrixLeadId
      }
    }
  }
`

export function sendPostBack({ bitrixId, uid }) {
  fetch(
    `https://advertiseru.org/postback/${process.env.GATSBY_ADVERTISE_TRACKING}/?token=${process.env.GATSBY_AVDERTISE_TOKEN}&uid=${uid}&order_id=${bitrixId}`
  )
}

export function sendPostBackAdmitad(bitrixId, admitad_uid) {
  fetch(
    `https://ad.admitad.com/r?campaign_code=${process.env.ADMITAD_CAMPAIGN_CODE}&postback=1&postback_key=${process.env.ADMITAD_POSTBACK_KEY}&action_code=1&uid=${admitad_uid}&order_id=${bitrixId}_1&tariff_code=1&quantity=1&position_id=1&position_count=1&product_id=1&payment_type=lead&currency_code=RUB`
  )
}

export function sendCityAdsPostback(bitrixId, cityads_click_id, utmTerm) {
  const orderId = `${bitrixId}_lead_${
    utmTerm === 'cityads_coupon' ? ' coupon' : 'other'
  }`
  fetch(
    `https://postback.cityads.com/service/postback/?&order_id=${orderId}&click_id=${cityads_click_id}&campaign_secret=pVg2vo&customer_type=lead${
      utmTerm ? `&utm_term=${utmTerm}` : ''
    }`
  )
}

export function sendAdvCakePostback(bitrixId, trackId, url) {
  fetch(
    `https://api.ekacvda.com/postback/youtalkru?id=${bitrixId}&totalPrice=0&coupon=&clientType=new&trackId=${trackId}&url=${url}&leadName=registration`
  )
}

export const sendFeedback = (data) =>
  fetch(`${baseURL}/api/public/leads/feedback`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  })
    .catch(handleErrors)
    .then(handleErrors)

export const getLeads = async () => {
  const response = await fetch(`${baseURL}/api/public/leads/count`, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  })
  const bitrixResponse = await response.json()
  if (!bitrixResponse) {
    throw new Error(`Failed to get number of leads from Bitrix`)
  }
  return bitrixResponse
}

export const checkPromocodes = async (promocode) => {
  const response = await fetch(
    `${baseURL}/api/public/promocodes/validate?promocodeName=${promocode}`,
    {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    }
  )

  return response
}

export const PublicSubscriptionTypes = {
  FOOTER: 'FOOTER',
  ANXIETY_NOTE: 'ANXIETY_NOTE',
  PANIC_ATTACK_NOTE: 'PANIC_ATTACK_NOTE'
}

export const sendFormDataCarrotQuest = ({ name, phone, email }) => {
  window.carrotquest.track('Отправка заявки на подбор специалиста в битрикс')
  window.carrotquest.identify([
    { op: 'update_or_create', key: '$name', value: name },
    { op: 'update_or_create', key: '$email', value: email },
    { op: 'update_or_create', key: '$phone', value: phone }
  ])
}

export const apiGetToken = () => {
  const registerStorage =
    window.sessionStorage.getItem(REGISTRATION_WEBSITE_RESULT) ?? '{}'
  return JSON.parse(registerStorage)?.jwt ?? ''
}

export const getCarrotToken = async () => {
  const response = await fetch(`${baseURL}/api/client/carrotToken`, {
    headers: {
      Authorization: `Bearer ${apiGetToken()}`
    },
    method: 'GET'
  })

  return response.text()
}

export const authCarrot = async (userID) => {
  const hash = await getCarrotToken()

  if (window.carrotquest) {
    window.carrotquest.auth(userID, hash)
  }
}
