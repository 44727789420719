import { gql, useMutation } from '@apollo/client'

export const LEAD_SUBSCRIBE = gql`
  mutation leadSubscribe($input: LeadSubscribeInput!) {
    leadSubscribe(input: $input) {
      ... on LeadSubscribeResponse {
        status
      }
    }
  }
`

export const useLeadSubscribe = () => useMutation(LEAD_SUBSCRIBE)
